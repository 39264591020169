import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";

//Importamos para verificar el estado de autenticación
import "./firebaseconf.js";

//Se encarga de manejar las vistas [para ser Single Pages App]
import App from "./componentes/Main/App.js";
import AppAdministrador from "./componentes/Administracion/AppAdministrador.js";
import AppDeUsuario from "./componentes/SesionIniciada/AppDeUsuario.js";

// Objetos personales
import Usuario from "./componentes/SesionIniciada/ObjetoUsuario.js";

//Escuchador del estado de sesión del usuario
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Importamos los componentes a Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore";

const auth = getAuth();
const db = getFirestore();

const container = document.getElementById("app");
const root = createRoot(container);

const div = document.getElementById("onload");
const body = document.getElementById("body");

// Al cargar se cambia el contenido del html
/*window.onload = function () {
  var delayInMilliseconds = 2000; // 2 seconds

  // div.className = body.className.replace('fondo-carga', 'ocultar-carga');
  // body.className = body.className.replace('ocultar-carga', '');

  setTimeout(function () {
    //your code to be executed after 3 seconds

    div.className = body.className.replace(
      "fondo-carga",
      "ocultar-fondo-carga"
    );
    body.className = body.className.replace("ocultar-carga", "");

    div.style.animationDuration = "1s";
    div.style.height = "0vh";
    div.style.opacity = 0;
  }, delayInMilliseconds);
}; */

onAuthStateChanged(auth, (user) => {

  // Contexto para el helmet
  const helmetContext = {};

  if (user) {
    // El usuario inició sesión

    // UID
    const mUid = user.uid;

    // Variable de tipo Usuario.class
    var mUsuario = new Usuario("", "", "", "", "", "", "", "", "", "", "", "");

    const ref = doc(db, "Usuarios", mUid).withConverter(
      Usuario.usuarioConverter
    );
    const docSnap = getDoc(ref);

    // Se piden los datos
    docSnap.then((snapshot) => {
      // Rellenamos
      mUsuario = snapshot.data();

      // Aquí se ve de qué tipo de usuario se trata y re-dirige
      if (mUsuario.admin) {
        // ReactDOM.render(<AppAdministrador />, container);
        root.render(
          <HelmetProvider context={helmetContext}>
            <AppAdministrador />
          </HelmetProvider>
        );
      } else if (!mUsuario.admin) {
        // ReactDOM.render(<AppDeUsuario/>, container);
        root.render(
          <HelmetProvider context={helmetContext}>
            <AppDeUsuario />
          </HelmetProvider>
        );
      }

      return Promise.resolve(docSnap);
    });
  } else {
    // El usuario cerró sesión

    // ReactDOM.render(__qué__, __dónde__);
    // ReactDOM.render(<App/>, container);
    root.render(
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
    );
  }
});
