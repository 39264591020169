import React from 'react';

import "../../global.css";
import "./estilos/BadgeLeerPost.css";

//Iconos importados
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png';
// import ElementoNuevo from '../Contenido/H1/index.js';

// ------------------------

class BadgeLeerPost extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        // console.log(idReferencia);

        // const mUid = this.props.autor;


        // Estado
        this.state = {
            // estiloElemento: { opacity: 1, color: '#f91880' }
            estiloElemento: { opacity: 1 }
        };
        
        
        // ----------------------------

        this.handleCompartir = this.handleCompartir.bind(this);
    }

    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }

    
    /* Lo que mostramos en pantalla */
    render(){

        return(

            
            <div className="BadgeLeerPost" theme={this.props.theme}>


                {/* Firma del autor */}
                <div className="BadgeLeerPost-firma" theme={this.props.theme}>

                    <img className='BadgeLeerPost-firma-avatar' src={this.props.rutaFotoPerfil} alt="Imagen del autor"/>
                    <div>
                        <span className='BadgeLeerPost-firma-nombre'>{this.props.nombrePublico}</span>
                        <img className='BadgeLeerPost-firma-verificado' src={iconCuentaVerificada} hidden={!this.props.verificado} alt="Estado de cuenta verificada"/>
                        <span className='BadgeLeerPost-firma-usuario'>@{this.props.nombreUsuario}</span>
                    </div>
                </div>

                {/* Categoría */}
                <div hidden={!this.props.categoria} className="BadgeLeerPost-categoria" theme={this.props.theme} id={this.props.idReferencia + "_div"} title="Categoría">
                    {this.props.categoria}
                </div>

                {/* Texto */}
                <p className="BadgeLeerPost-texto" theme={this.props.theme}>{this.props.texto}</p>
                
                {/* <ElementoNuevo
                    textoPlano = {this.props.textoPlano}
                    estilo = {this.props.estilo}
                    /> */}

                {/* Hora y Fecha */}
                <span className="BadgeLeerPost-fecha" theme={this.props.theme} title="Fecha de publicación">{this.props.fullDateString}</span>
            
            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeLeerPost;